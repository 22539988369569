import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FooterColumn from './FooterColumn/FooterColumn';
import {
  Divider,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>({
  root: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    padding: '0px',
    margin: '0px',
  },
  divider: {
    backgroundColor: theme.palette.text.main,
  },
  container: {
    display: 'flex',
    justifyContent: "space-evenly",
    marginLeft: "10vw",
    marginRight: "10vw",
    width: "75vw",
  },
  listContainer: {
    width: '100%',
    padding: '0px',
    margin: '0px',
  }
}));

const BottomBar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <ul className={classes.listContainer}>
        <Divider classes={{root: classes.divider}}/>
        <div className={classes.container} >
            <FooterColumn header={"Company"} links={["About Us"]} />
            <FooterColumn header={"Contact"} links={["Contact Us"]} />
        </div>
      </ul>
    </div>
  );
};

BottomBar.propTypes = {
  className: PropTypes.string
};

export default BottomBar;
