import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import appData from '../AppData';
import ffImage from '../assets/images/ffImage.png';
import { Avatar } from '@mui/material';
import headshot from "../assets/images/headshot.jpg";

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.light,
        padding: '20px', 
        backgroundColor: "#FFFFFF",
    },
    text: {
        color: theme.palette.text.light,
        fontSize: '18px',
    },
    homeContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    cardImage: {
        maxWidth: "100%",
    }
}));

export default function Home() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.homeContainer}>
                <div>
                    <Typography variant="h3" color="header.light">Mission</Typography>
                    <Typography variant="body1" color="text.light">
                        Honest applications is making applications with transparent data collection and monetization strategies. We dont collect or sell your data and we respect your privacy.
                    </Typography>
                </div>

                <div>
                    <Typography variant="body1" color="text.light">
                        Below is a showcase of our vast portfolio of applications.
                    </Typography>
                </div>

                <div style={{ marginTop: "20px", display: 'flex', alignItems: 'center', flexDirection: 'column'  }}>
                    <Typography variant="h3" color="header.light">Portfolio</Typography>
                    <Card sx={{ minWidth: 275, maxWidth: "20vw" }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            FlicFuse
                            </Typography>
                            <img src={ffImage} alt="flicfuse" className={classes.cardImage} />                
                        </CardContent>
                        <CardActions>
                            <a href={appData.ffLink}>Learn More</a>
                        </CardActions>
                    </Card>
                </div>

                <div style={{ marginTop: "20px", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <Typography variant="h3" color="header.light">About Us</Typography>

                    <Card sx={{ width: '50vw' }}>
                        
                        <CardContent sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'  }}>
                            <Avatar alt="Justin Kellett" sx={{ width: 96, height: 96 }} src={headshot} />
                            <Typography variant="body1" color="text.light">
                                Hello, my name is Justin and I founded Honest applications to create high quality ethically monetized apps that solve problems. Honest applications is making applications with transparent data collection and monetization strategies. We dont collect or sell your data and we respect your privacy.
                            </Typography>
                            <Typography variant="body1" color="text.light">
                                Feel free to reach me at {appData.email}
                            </Typography>
                        </CardContent>

                    </Card>
                </div>
            </div>
        </div>
    );
}
