import React from 'react';
import { makeStyles } from '@mui/styles';
import appData from '../AppData';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.light,
        padding: '0px', 
        backgroundColor: "#FFFFFF",
    },
    text: {
        color: theme.palette.text.light,
        fontSize: '18px',
    },
    aboutContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '20vw',
        marginRight: '20vw',
    },
}));

export default function AboutUs() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.aboutContainer}>
                <h2 className={classes.root}>About Us</h2>
                <p>{appData.title} is a software and application development company, we create ethical, high quality applications, without exploiting user data, resources, or through manipulation. We are building an ecosystem of applications that improve our users experiences.</p>       
            </div>
        </div>
    );
}
