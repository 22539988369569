import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { 
    useNavigate,
  } from 'react-router-dom';

export default function HAAppBar() {
    const navigate = useNavigate();
    
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                <Typography onClick={()=>{ navigate("/") }} variant="h6" component="div" sx={{ flexGrow: 1, textAlign: "left" }}>
                    Honest Applications
                </Typography>
                </Toolbar>
            </AppBar>
            </Box>);
}