import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';

import BottomBar from './BottomBar';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import Home from './pages/Home';
import HAAppBar from './AppBar/AppBar';

function App() {
  return (
    <div className="App">
      
      <Router>
        <Routes>
          <Route path="/" element={
              <div>
                <HAAppBar/>
                <Home/>
                <BottomBar />
              </div>
            }>
            </Route>

            <Route path="/ContactUs" element={
              <div>
                <HAAppBar/>
                <ContactUs/>
                <BottomBar />
              </div>
            }>
            </Route>

            <Route path="/AboutUs" element={
              <div>
                <HAAppBar/>
                <AboutUs/>
                <BottomBar />
              </div>
            }>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
