import { createTheme } from '@mui/material/styles';

const theme = createTheme({
   palette: {
    primary: {
      light: '#757ce8',
      main: '#3399ff',
      dark: 'rgba(12,22,45,1)',
      trans_dark: 'rgba(12,22,45,0.90)',
      contrastText: '#fff',
      outline: "#666",
      topBar: "#AAA"
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#3b444b',
      trans_dark: 'rgba(59, 68, 75, 0.4)',
      contrastText: '#000',
      outline: "#999",
    },
    text: {
      dark: "#EEEEEE",
      light: "#000000",
    },
    header: {
      dark: "#AAAAAA",
      light: "#99ccff",
    },
    outline: {
      dark: "#ffffff",
    }
  },
  breakpoints: {
    values: {
        mobile: 220,
        tablet:  650,
        laptop: 1024,
        desktop: 1600,
        xxs: 1,
        xs: 450,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    keys: ['mobile', 'tablet','desktop', 'xs', 'xxs', 'sm','md','lg','xl'],
  },
});
export default theme;