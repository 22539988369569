import React from 'react';
import { makeStyles } from '@mui/styles';
import appData from '../AppData';

const useStyles = makeStyles((theme) => ({
    root: {
        borderColor: theme.palette.text.light,
        color: theme.palette.text.light,
        padding: '0px', 
        backgroundColor: "#FFFFFF",
    },
    text: {
        color: theme.palette.text.light,
        fontSize: '18px',
    },
    contactContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '20vw',
        marginRight: '20vw',
    },
    textFieldInput: {
        color: `${theme.palette.text.light} !important`,
        zIndex: 3,
        '& input': {
          color: `${theme.palette.text.light} !important`,
          zIndex: 3,
        },
    },
    message: {
        marginBottom: '10px',
    },
    submitButton: {
        color: theme.palette.text.light,
        width: '10vw',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '10px !important',
        borderColor: theme.palette.text.light,
    },
    spacedField: {
        marginBottom: '10px !important',
    },
    cssOutlinedInput: {
        color: `${theme.palette.text.light} !important`,
        '& .MuiInputBase-root' : {
            color: `${theme.palette.text.light} !important`,
            backgroundColor: `${theme.palette.primary.light } !important`,
            overflow: 'hidden',
        },
        '& .MuiPaper-root': {
          color: `${theme.palette.text.light} !important`,
    
        },
        '.MuiOutlinedInput': {
            color: `${theme.palette.text.light} !important`,
          borderColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: `${theme.palette.outline.light} !important`,
            color: `${theme.palette.text.light} !important`,
    
          }},
        '& .MuiFormLabel-root' : {
            color: `${theme.palette.text.light} !important`,
            backgroundColor: `${theme.palette.primary.light } !important`,
            zIndex: 4,
        },
        '& .MuiSvgIcon-root' : {
            color: `${theme.palette.text.light} !important`,
        },
      },
}));

export default function FAQ() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.contactContainer}>
                <h2 className={classes.root}>Contact Us</h2>
                <p>Any questions can be directed to {appData.email}.</p>
            </div>
        </div>
    );
}
