import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { 
  useNavigate,
} from 'react-router-dom';

const useStyles = makeStyles((theme) =>({
  root: {
    // backgroundColor: theme.palette.primary.dark,
    zIndex: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    color: theme.palette.header.light,
    padding: "0px",
    margin: "4px",
    fontSize: "20px",
    [theme.breakpoints.up('md')]: {
      fontSize: "24px",
    },
  },
  link: {
    color: theme.palette.text.dark,
    fontSize: "14px",
    padding: "0px",
    margin: "4px",
    cursor: 'pointer',

    [theme.breakpoints.up('md')]: {
      fontSize: "18px",
    },
  },
}));

const filter = (item)=> {
  let ret = item.toLowerCase();
  let sp = ret.split(' ');
  let le = sp.length - 1;
  let service = sp[le];
  if ( service  === 'max' || service === 'video') {
    le -=1;
    if (service  === 'max') {
      service = 'hbo max';
    } else{
          service = 'prime video';
    }
  }
  sp = sp.splice(0, le);
  ret = sp.join('')
  ret = ret.replaceAll("'", "");

  console.log(service);
  if (['netflix', 'hulu', 'hbo max', 'prime video'].includes(service)) {

    return `${ret}/${service}`;
  }
  return `${ret}${service}`;
};

const FooterColumn = ({ className, links, header }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (   
    <div className={classes.root}>
        <div className={classes.container}> 
          <h3 className={classes.header}>{header}</h3>
          {links.map(l => {
              return <p key={l}  name={l} className={classes.link} onClick={()=>navigate(`/${filter(l)}`)}>{l}</p>;
          })}
        </div>
    </div>
  );
};

FooterColumn.propTypes = {
  className: PropTypes.string
};

export default FooterColumn;